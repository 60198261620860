import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { LoginPageComponent } from "./pages/content-pages/login/login-page.component";

import { AuthGuard } from './shared/auth/auth-guard.service';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },

  //AuthGuard
  { 
    path: '', 
    component: FullLayoutComponent, 
    data: { title: 'full Views' }, 
    children: Full_ROUTES,
    canActivate: [AuthGuard] 
  },
  { 
    path: '', 
    component: ContentLayoutComponent, 
    data: { title: 'content Views' }, 
    children: CONTENT_ROUTES, 
    canActivate: [AuthGuard] 
  },
  
  //NotFound
  {
    path: '**', 
    redirectTo: 'pages/error',
    pathMatch: 'full',
  } 
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)], /*{ enableTracing: true }*/
  exports: [RouterModule]
})

export class AppRoutingModule {
}