import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { URLSearchParams } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Admin } from '../models/admin';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/Rx';

@Injectable()
export class AdminsService {

  constructor(private http: HttpClient) {}

  /**
   * Get All Admins
   */
  getAdminsList(){
    return this.http.get(`${environment.shareViewApiUrl}/admins`) //, {observe: 'response'}
  }

  /**
   * get Admin that matches to id
   */
  getAdmin(admin_id : string){
    return this.http.get(`${environment.shareViewApiUrl}/admin/${admin_id}`)
  } 
  
  /**
   * Create admin that matches to id
   * @param admin:Admin
   */
  createAdmin(admin: Admin){
    let body = new URLSearchParams();

    body.set('firstname', admin.firstname);
    body.set('lastname', admin.lastname);
    body.set('username', admin.username);
    body.set('email', admin.email);
    body.set('password', admin.password);
    body.set('password_repeat', admin.password_repeat);
    body.set('facebook_token', admin.facebook_token);
    body.set('phone', admin.phone);

    return this.http.post(`${environment.shareViewApiUrl}/admins`, body.toString());  
  }

  /**
   * Updates admin that matches to id
   * @param admin:Admin
   */
  updateAdmin(admin : Admin){
    let body = new URLSearchParams();
    body.set('firstname', admin.firstname);
    body.set('lastname', admin.lastname);
    body.set('username', admin.username);
    body.set('email', admin.email);
    body.set('facebook_token', admin.facebook_token);
    body.set('phone', admin.phone);

    if(admin.password !== null && admin.password_repeat !== null){
      body.set('password', admin.password);
      body.set('password_repeat', admin.password_repeat);
    }

    return this.http.put(`${environment.shareViewApiUrl}/admin/${admin.id}`, body.toString())
  }

  /**
   * Delete admin that matches to id
   * @param admin_id:String
   */
  deleteAdmin(admin_id: string){
    return this.http.delete(`${environment.shareViewApiUrl}/admin/${admin_id}`);
  }

  /**
   * reset admin password 
   * @param email:String
   */
  resetPassword(email: string){
    let body = new URLSearchParams();
    body.set('email', email);

    return this.http.post(`${environment.shareViewApiUrl}/reset-password`, body.toString());
  }
  
}
