import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'user',
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule'
  },
  {
    path: 'banish',
    loadChildren: './banish/banish.module#BanishModule'
  },
  {
    path: 'subscription',
    loadChildren: './subscription/subscription.module#SubscriptionModule'
  },
  {
    path: 'album',
    loadChildren: './album/album.module#AlbumModule'
  }
];