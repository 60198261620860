import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from './../../../shared/auth/auth.service';
import { doSwalForgotPassword, doSwalSuccess, doSwalError } from './../../../services/swal.services';
import { AdminsService } from './../../../services/admins.services';
 
@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent {

    @ViewChild('f') loginForm: NgForm;

    constructor(
        private router: Router, 
        private route: ActivatedRoute, 
        private authService: AuthService,
        private adminsService: AdminsService) { }

    // On submit button click    
    onSubmit(f: NgForm) {
        if(f.valid)
            this.authService.signinUser(f.value.inputPseudo, f.value.inputPass); 

        this.loginForm.reset();
    }

    onForgotPassword(){
        doSwalForgotPassword().then(
            (email) => {
                this.adminsService.resetPassword(email.toString()).subscribe(
                    (success: any) => {
                        doSwalSuccess(
                            'Vous allez recevoir votre nouveau mot de passe par e-mail'
                        );
                    },
                    (error: any) => {
                        doSwalError(error.message);
                        console.log(error);    
                    }
                );
            } 
        )
    }
    
}