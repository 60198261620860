import swal from 'sweetalert2';

export function doSwalError(message){
    swal({
        type: 'error',
        title: message,
        showConfirmButton: false,
        timer: 2500
    });
}

export function doSwalConfirmDelete(){
    return swal({
        title: 'Supprimer',
        text: "Voulez-vous vraiment supprimer cet élément ? ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accepter',
        cancelButtonText: 'Annuler'
      })
}

export function doSwalConfirmBanish(){
    return swal({
        title: 'Bannir',
        text: "Voulez-vous vraiment bannir cet utilisateur ? ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accepter',
        cancelButtonText: 'Annuler'
      })
}

export function doSwalConfirmBanishOff(){
    return swal({
        title: 'Débannir',
        text: "Voulez-vous vraiment retirer le banissement de cet utilisateur ? ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accepter',
        cancelButtonText: 'Annuler'
      })
}

export function doSwalSuccess(message){
    swal({
        type: 'success',
        title: message,
        showConfirmButton: false,
        timer: 2500
    });
}

export async function doSwalForgotPassword(){
    return swal({
        title: 'Merci de renseigner votre adresse email',
        input: 'email',
        inputPlaceholder: 'Email'
    })
}
