import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    HttpRequest,
    HttpHandler,
    HttpResponse,
    HttpEvent,
    HttpHeaders,
    HttpErrorResponse,
    HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(public auth: AuthService, public router: Router) { }

    addAccessToken(request: HttpRequest<any>): HttpRequest<any>{
        return request.clone({
            /*setHeaders: {
              Authorization: `Bearer ${this.auth.getToken()}`
            },*/
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.auth.getToken()}`,
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
            })
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //Add the access token to the request
        request = this.addAccessToken(request);

        return new Observable<HttpEvent<any>>(subscriber=>{
            next.handle(request).subscribe(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        // the request went well and we have valid response
                        // give response to user and complete the subscription
                        subscriber.next(event);
                        subscriber.complete();
                    }
                },
                (err) => {
                if (err instanceof HttpErrorResponse) {
                    switch (err.status) {
                        case 401:
                            console.log('Access_token possibly expired, trying to refresh!')
                            this.auth.refreshToken().subscribe(newTokens => {
                                //Set the new access_token
                                request = this.addAccessToken(request);
                                next.handle(request).subscribe(
                                    (newEvent: HttpEvent<any>) => {
                                        if (newEvent instanceof HttpResponse) {
                                            // the second try went well and we have valid response
                                            // give response to user and complete the subscription
                                            subscriber.next(newEvent);
                                            subscriber.complete();
                                        }
                                    },
                                    error => {
                                        // second try went wrong -> throw error to subscriber
                                        subscriber.error(error);
                                    }
                                );
                            })
                            break;
//                            this.handle401Error(request, next);
                        case 400:
                            console.log('Refresh_token possibly expired, redirecting to /login');
                            if (err && err.status === 400 && err.error && err.error.error === 'invalid_grant') {

                                this.auth.logout();
                                this.router.navigateByUrl('/login');

                            }
                        default:
                            subscriber.error(err);
                            break;
                    }
                }
                else {
                    subscriber.error(err);
                }
            });
        });
    }

}
