import { Router } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { of as observableOf, Observable } from 'rxjs';
import { doSwalError, doSwalConfirmDelete, doSwalSuccess } from '../../services/swal.services';
import { delay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';



@Injectable()
export class AuthService {

    token: string;
    isRefreshingToken:boolean = false;
    refreshTokenObservable;
    constructor(
        public http: HttpClient,
        public router: Router,
        private _location: Location
    ) { }

    signinUser(pseudo: string, password: string): void {
        //signing up the new user
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8')
        };


        let body = new URLSearchParams();
        body.set('client_id', environment.client_id);
        body.set('client_secret', environment.client_secret);
        body.set('grant_type', 'password');
        body.set('username', pseudo);
        body.set('password', password);

        let errHandler = function(err){
            doSwalError(err.message);
            console.log(err);
        }

        this.http.post(`${environment.shareViewApiUrl}/oauth2/token`, body.toString(), options)
            .subscribe(
                data => {
                    //save token
                    localStorage.setItem('access_token', data["access_token"]);
                    localStorage.setItem('refresh_token', data["refresh_token"]);

                    //then we call the /me to grab some usefull infos about the currently logged user
                    this.http.get(`${environment.shareViewApiUrl}/me`).subscribe(
                        data_me=>{
                            localStorage.setItem('role', data_me["role"]);
                            this.router.navigateByUrl('/');
                            doSwalSuccess('Identification réussie');
                        },
                        error_me=>errHandler
                    )
                },
                err => errHandler
            );
    }

    logout() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('role');
    }

    getToken(): string {
        return localStorage.getItem('access_token');
    }

    getRefreshToken() {
        return localStorage.getItem('refresh_token');
    }

    getRole() {
        return localStorage.getItem('role');
    }

    refreshToken() {
        let that = this;
        if(!this.isRefreshingToken){
            this.isRefreshingToken = true;
            //signing up the new user
            let options = {
                headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8')
            };

            let body = new URLSearchParams();
            body.set('client_id', environment.client_id);
            body.set('client_secret', environment.client_secret);
            body.set('grant_type', 'refresh_token');
            body.set('refresh_token', this.getRefreshToken());


            this.refreshTokenObservable = Observable.create(observer => {

                this.http.post(`${environment.shareViewApiUrl}/oauth2/refresh-token`, body.toString(), options).share()
                .subscribe(
                    data => {
                        //save token
                        console.log('refresh_token ok');
                        localStorage.setItem('access_token', data["access_token"]);
                        localStorage.setItem('refresh_token', data["refresh_token"]);

                        observer.next(data);
                        observer.complete();
                        that.isRefreshingToken = false;
                        that.refreshTokenObservable = false;
                    },
                    err => {
                        console.log('refresh error', err);
                        observer.error(err);
                    }
                )
            }).share();
        }
        return this.refreshTokenObservable;

    }


    isAuthenticated(): boolean {
        return this.getToken() !== null;
    }

}
