// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  oauth2Url: 'https://api.share-view.com',
  shareViewApiUrl: 'https://api.share-view.com',
  client_id: 'client_shareview_bo',
  client_secret: 'mnQ8x1Mp3m',
  redirectUri: ''
};
